<template>
  <div :id="id" :class="type" class="rz-message" >
    {{ content }}
  </div>
</template>

<script>
export default {
  name: "message",
  props: {
    type: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    },
    id: {
      type: String
    },
    timeOut: {
      type: Number,
      default: 3000
    }
  },
  mounted() {
    setTimeout(() => {
      document.getElementById(this.id).remove()
    }, this.timeOut)
  }
}
</script>

<style lang="scss" scoped>
.rz-message {
  position: fixed;
  top: 0;
  left: 0;
  padding: .2rem;
  box-sizing: border-box;
  width: 100%;
  animation: show .5s linear;
  text-align: center;
  font-size: .2rem;
  color: white;
  z-index: 111;

  &.success {
    background: $--color-success;
  }

  &.danger ,&.error{
    background: $--color-danger;
  }

  &.worning {
    background: $--color-warning;
  }
}

@keyframes show {
  0% {
    top: -2rem
  }
  100% {
    top: 0
  }
}
</style>
