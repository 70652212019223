import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './utils/rem'
import modal from '@/utils/modal'
import message from "@/utils/Message";
Vue.config.productionTip = false
Vue.prototype.$modal = modal
Vue.prototype.$message = message
new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
