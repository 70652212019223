import Vue from 'vue'
import messageComponent from "@/components/message";

const modal = function (options = {}) {
    const ms = Date.parse(new Date())
    const id = 'message' + ms
    let body = document.body,
        dom = document.createElement('div')
    dom.setAttribute('id', id)
    body.appendChild(dom)
    return new Vue({
        render: (h) => {
            return h(
                messageComponent,
                {
                    props: {
                        ...options,
                        id
                    }
                }
            )
        }
    }).$mount('#' + id)
}

export default modal
