<template>
  <div :id="id">
    <div v-if="visible" class="modal-mask" @click.self="maskClick">
      <div class="modal-wrap">
        <div class="modal-wrap-title">
          <span v-if="!$slots.header">{{ title }}</span>
          <slot  name="header"></slot>
        </div>
        <div v-if="!$slots.content" class="modal-wrap-content" v-html="content"></div>
        <slot v-else name="content"></slot>
        <div class="modal-wrap-btn">
          <div class="rz-button cancel" v-if="showCancel" @click="handleCancel">{{ cancelText }}</div>
          <div class="rz-button confirm"v-if="showConfirm" @click="handleConfirm">{{ confirmText }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "modal",
  model: {
    prop: 'visible',
    event: "change"
  },
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    content: {
      type: String
    },
    id: {
      type: String
    },
    title: {
      type: String
    },
    onConfirm: {
      type: Function,
    },
    onCancel: {
      type: Function,
    },
    showCancel: {
      type: Boolean,
      default: true
    },
    showConfirm: {
      type: Boolean,
      default: true
    },
    cancelText: {
      type: String,
      default: "取消"
    },
    confirmText: {
      type: String,
      default: "确定"
    },
    closeByClickModal: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
  },
  methods: {
    maskClick() {
      if (this.closeByClickModal) this.close()
    },
    close() {
      const dom = document.getElementById(this.id)
      dom.classList.add('close')
      setTimeout(() => {
        dom.remove()
      }, 100)
    },
    handleCancel() {
      this.close()
      this.onCancel && typeof this.onCancel === 'function' && this.onCancel()
    },
    handleConfirm() {
      this.onConfirm && typeof this.onConfirm === 'function' && this.onConfirm(this.close)
    },
  }
}
</script>

<style lang="scss" scoped>
.modal-mask {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, .3);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: all .15s linear;

  &.close {
    opacity: 0;
  }

  .modal-wrap {
    width: 90%;
    background: white;
    padding: .2rem;
    box-sizing: border-box;
    border-radius: .2rem;

    &-title {
      padding-bottom: .2rem;
      text-align: center;
      margin-bottom: .5rem;
      font-size: .3rem;
      font-weight: bolder;
    }

    &-content {
      font-size: .26rem;
      max-height: 5rem;
      overflow-y: auto;
      padding: 0 .2rem;
      box-sizing: border-box;
    }

    &-btn {
      text-align: center;
      margin-top: .3rem;

      .rz-button {
        width: 1.5rem;
        line-height: .6rem;
      }
    }
  }
}

</style>
